<template>
  <div class="container pt-4">
    <button
      type="button"
      class="btn btn-success ms-2"
      @click="type = 'checkdown'"
    >
      Checkdown
    </button>
    <button
      type="button"
      class="btn btn-success ms-2"
      @click="type = 'arithmetic'"
    >
      Arithmetic
    </button>
    <button
      type="button"
      class="btn btn-success ms-2"
      @click="type = 'magnitude'"
    >
      Magnitude
    </button>
    <Checkdown v-if="type === 'checkdown'" />
    <SimpleArithmetic v-if="type === 'arithmetic'" />
    <Magnitude v-if="type === 'magnitude'" />
  </div>
</template>

<script>
import Checkdown from "./components/Checkdown.vue";
import SimpleArithmetic from "./components/SimpleArithmetic.vue";
import Magnitude from "./components/Magnitude.vue";
export default {
  data() {
    return {
      type: null,
    };
  },
  components: {
    Checkdown,
    SimpleArithmetic,
    Magnitude,
  },
};
</script>

<style></style>
