<template>
  <div class="d-inline-block">
    <button
      type="button"
      class="btn btn-primary ms-2 font-monospace border border-light"
      v-if="value && value.operator"
      :class="{ disabled: highlight }"
    >
      <CheckdownNumber :value="value.left" /> {{ value.operator }}
      <CheckdownNumber :value="value.right" />
    </button>
    <button
      type="button"
      class="btn btn-primary ms-2 font-monospace border border-light"
      v-if="value && !value.operator"
      :class="{ disabled: highlight }"
    >
      {{ value }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CheckdownNumber",
  props: {
    value: { type: [Number, Object] },
    highlight: { type: Boolean },
  },
};
</script>

<style></style>
