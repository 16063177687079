import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

import VueKatex from "vue-katex";
import "katex/dist/katex.min.css";

Vue.use(VueKatex, {
  globalOptions: {
    //... Define globally applied KaTeX options here
  },
});

new Vue({
  render: (h) => h(App),
}).$mount("#app");
