import _ from "lodash";

const arithmeticTypes = ["addition", "addition2", "multiplication", "division"];

let getArithmeticFunction = function (type) {
  switch (type) {
    case "addition": {
      let x = _.random(-1000, 1000);
      let y = _.random(-1000, 1000);
      return `${x} ${y < 0 ? "" : "+"} ${y}`;
    }
    case "addition2": {
      let x = _.random(-200, 200);
      let y = _.random(-200, 200);
      let z = _.random(-200, 200);
      return `${x} ${y < 0 ? "" : "+"} ${y} ${z < 0 ? "" : "+"} ${z}`;
    }
    case "multiplication": {
      let x = _.random(-100, 100);
      let y = _.random(-100, 100);
      return `${x} * ${Math.abs(y)}`;
    }
    case "division": {
      let x = _.random(10, 100);
      let y = _.random(3, 20);
      return `${x * y} / ${y}`;
    }
  }
};

function scientificishNotation(p_num, p_precision) {
  p_precision = p_precision || 1;
  var n = Math.round(Math.log10(Math.abs(p_num)));
  var m = (p_num * Math.pow(10, -n + 1)).toFixed(p_precision);
  return m.toString() + " * 10^{" + (n - 1).toString() + "}";
}

let getMagnitudeFunction = function () {
  let x = _.random(-1, 1, true) * Math.pow(10, _.random(-9, 9));
  let y = _.random(-1, 1, true) * Math.pow(10, _.random(-9, 9));
  let z = _.random(-1, 1, true) * Math.pow(10, _.random(-9, 9));
  let u = _.random(-1, 1, true) * Math.pow(10, _.random(-9, 9));
  return `(${scientificishNotation(x)} * ${scientificishNotation(
    y
  )} * ${scientificishNotation(z)})\\over(${scientificishNotation(u)})`;
};

export default {
  newArithmeticFunction() {
    let equation = getArithmeticFunction(
      arithmeticTypes[_.random(0, arithmeticTypes.length - 1)]
    );
    let solution = eval(equation);
    return {
      problem: equation,
      test(input) {
        return (
          input === solution || parseFloat(input) === solution //||   evaluate(input) === solution
        );
      },
      solution,
      keyboard: "simple",
      type: "arithmetic",
    };
  },
  newMagnitudeFunction() {
    let equation = getMagnitudeFunction();
    let solution = eval(
      equation
        .replace(/{/g, "(")
        .replace(/\}/g, ")")
        .replace(/\^/g, "**")
        .replace(/\\over/g, "/")
    );
    return {
      problem: equation,
      test(input) {
        return (
          input === solution ||
          (parseFloat(input) / solution < 1.1 &&
            parseFloat(input) / solution > 0.9) //||   evaluate(input) === solution
        );
      },
      solution: scientificishNotation(solution),
      keyboard: "simple",
      type: "magnitude",
    };
  },
};
