<template>
  <div class="container pt-4">
    <div class="alert alert-info">
      <h1>
        Target :
        <button type="button" class="btn btn-lg btn-info ms-2 font-monospace">
          {{ target }}
        </button>
      </h1>
      <CheckdownNumber
        :value="leftNumber"
        @click="this.selectedSide = 'left'"
      />
      <span class="font-monospace ms-2 me-2">{{ operator }}</span>
      <CheckdownNumber
        :value="rightNumber"
        @click="this.selectedSide = 'right'"
      />
      <button
        type="button"
        class="btn btn-danger btn-sm ms-2"
        @click="resetSelection"
        v-if="leftNumber || rightNumber || operator"
      >
        Clear
      </button>
    </div>
    <CheckdownNumber
      :key="'n' + index"
      v-for="(number, index) of availableNumbers"
      @click.native="useNumber(number, index)"
      :highlight="
        (leftNumber === number && leftNumberIndex === index) ||
        (rightNumber === number && rightNumberIndex === index)
      "
      :value="number"
    />
    <div :key="'r' + index" v-for="(result, index) of results">
      <button
        type="button"
        class="btn btn-warning ms-2 font-monospace"
        @click="breakResult(result)"
      >
        break
      </button>
      <CheckdownNumber @click.native="useNumber(result, -1)" :value="result" />
    </div>
    <div class="mt-2">
      <button
        :key="'o' + index"
        v-for="(operator, index) of operators"
        type="button"
        class="btn btn-info ms-2 font-monospace"
        @click="useOperator(operator)"
      >
        {{ operator }}
      </button>
    </div>
    <div class="mt-2">
      <button type="button" class="btn btn-warning ms-2" @click="newQuestion">
        Skip
      </button>
      <button type="button" class="btn btn-danger ms-2" @click="reset">
        Reset
      </button>
      <button type="button" class="btn btn-success ms-2" @click="check">
        Check
      </button>
      <div class="btn btn-success disabled ms-2">
        {{ goodAnswersCounter }} ✓
      </div>
    </div>
    <div v-if="difference !== null" class="mt-2">
      <div class="alert alert-success" v-if="difference === 0">Perfect !</div>
      <div
        class="alert alert-success"
        v-else-if="difference < 3 && difference > -3"
      >
        Close ! diff = {{ difference }}
      </div>
      <div class="alert alert-warning" v-else>diff = {{ difference }}</div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import checkdownHelper from "@/helpers/checkdown.helper.js";

import CheckdownNumber from "./CheckdownNumber.vue";

export default {
  components: { CheckdownNumber },
  data() {
    return {
      originalNumbers: [],
      results: [],
      operators: ["+", "-", "*", "/"],
      operator: null,
      leftNumber: null,
      rightNumber: null,
      leftNumberIndex: null,
      rightNumberIndex: null,
      selectedSide: "left",
      target: null,
      difference: null,
      goodAnswersCounter: 0,
    };
  },
  created() {
    this.newQuestion();
  },
  methods: {
    newQuestion() {
      this.reset();
      this.target = checkdownHelper.generateTarget();
      this.originalNumbers = checkdownHelper.generateNumbers();
    },
    useOperator(operator) {
      this.operator = operator;
      if (this.leftNumber && this.rightNumber && this.operator) {
        this.addResult();
      }
    },
    useNumber(number, index) {
      if (
        this.selectedSide === "left" &&
        this.rightNumber !== number &&
        this.rightNumberIndex !== index
      ) {
        this.leftNumber = number;
        this.leftNumberIndex = index;
        this.selectedSide = "right";
      } else if (this.leftNumber !== number || this.leftNumberIndex !== index) {
        this.rightNumber = number;
        this.rightNumberIndex = index;
      }
      if (this.leftNumber && this.rightNumber && this.operator) {
        setTimeout(() => this.addResult(), 500);
      }
    },
    addResult() {
      this.results.push({
        left: this.leftNumber,
        right: this.rightNumber,
        operator: this.operator,
      });
      if (_.isObject(this.leftNumber)) {
        this.results = _.without(this.results, this.leftNumber);
      }
      if (_.isObject(this.rightNumber)) {
        this.results = _.without(this.results, this.rightNumber);
      }
      this.resetSelection();
    },
    resetSelection() {
      this.leftNumber = null;
      this.rightNumber = null;
      this.operator = null;
      this.selectedSide = "left";
      this.difference = null;
      this.leftNumberIndex = null;
      this.rightNumberIndex = null;
    },
    reset() {
      this.resetSelection();
      this.results = [];
    },
    breakResult(result) {
      if (_.isObject(result.left)) {
        this.results.push(result.left);
      }
      if (_.isObject(result.right)) {
        this.results.push(result.right);
      }
      this.results = _.without(this.results, result);
    },
    check() {
      let result = this.results[0];
      let output = checkdownHelper.flattenResultString(result);
      this.difference = this.target - eval(output);
      if (Math.abs(this.difference) < 3) {
        this.goodAnswersCounter++;
        setTimeout(() => this.newQuestion(), 2000);
      }
    },
  },
  computed: {
    availableNumbers() {
      let usedNumbers = _.countBy(
        _.flatMap(this.results, checkdownHelper.flattenResult)
      );
      let originalNumbers = _.countBy(this.originalNumbers);
      let output = [];
      for (let number in originalNumbers) {
        if (!usedNumbers[number]) {
          _.times(originalNumbers[number], () => output.push(parseInt(number)));
        } else if (usedNumbers[number] < originalNumbers[number]) {
          _.times(originalNumbers[number] - usedNumbers[number], () =>
            output.push(parseInt(number))
          );
        }
      }
      return output;
    },
  },
};
</script>

<style></style>
