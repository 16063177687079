import _ from "lodash";

const flattenResult = function (result) {
  let left = _.isObject(result.left)
    ? flattenResult(result.left)
    : [result.left];
  let right = _.isObject(result.right)
    ? flattenResult(result.right)
    : [result.right];
  return [...left, ...right];
};
const flattenResultString = function (result) {
  let left = _.isObject(result.left)
    ? flattenResultString(result.left)
    : result.left;
  let right = _.isObject(result.right)
    ? flattenResultString(result.right)
    : result.right;
  return `(${left} ${result.operator} ${right})`;
};

const generateTarget = function () {
  return _.random(100, 999);
};
const generateNumbers = function () {
  let numbers = [ 
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
    3, 3, 3, 3, 3, 3, 3, 3, 3, 3,
    4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 
    5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
    6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 
    7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 
    8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 
    9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 
    25, 25, 25, 25, 25, 25, 25, 25,
    10, 10, 10, 10, 10, 10, 
    75, 50, 75, 50,
  ] // prettier-ignore
  return _.times(6, () => numbers[_.random(0, numbers.length - 1)]);
};

export default {
  flattenResult,
  flattenResultString,
  generateTarget,
  generateNumbers,
};
