<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    value: {
      type: String,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "± 0 .", "E+ E- {bksp}"],
      },
      display: {
        "{bksp}": "←",
      },
      buttonTheme: [
        {
          class: "hg-red",
          buttons: "Q W E R T Y q w e r t y",
        },
        {
          class: "hg-highlight",
          buttons: "Q q",
        },
      ],
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
    });
  },
  beforeDestroy() {
    this.keyboard.destroy();
  },
  methods: {
    onChange(input) {
      if (input.match("±")) {
        if (input.match(/^-/)) {
          input = input.replace(/^-/g, "+");
        } else {
          input = "-" + input.replace(/^\+/g, "");
        }
        input = input.replace(/±/g, "");
      }
      // if (input.match(/\^/)) {
      //   if (input.match(/\^\+/)) {
      //     input = input.replace(/^-/g, "+");
      //   } else {
      //     input = "-" + input.replace(/^\+/g, "");
      //   }
      //   input = input.replace(/±/g, "");
      // }
      this.$emit("input", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);
    },
  },
  watch: {
    value(value) {
      this.keyboard.setInput(value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
